import { Link, Text, VSpacingContainer, HSpacing } from '@hh.ru/magritte-ui';
import { CircleFilledSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { VacancySavedSearchItem } from 'src/models/vacancySavedSearch';

import styles from './styles.less';

const TrlKeys = {
    withAddress: 'savedSearch.vacancy.withAddress',
    searchPeriod: 'vacancySearchResults.searchPeriod',
    searchPeriodDefault: 'vacancySearchResults.searchPeriod.30',
    fromAgencies: 'applicant.autosearch.vacancies.fromAgencies',
    woSalary: 'applicant.autosearch.vacancies.woSalary',
    exclude: 'applicant.autosearch.exclude',
    include: 'applicant.autosearch.include',
    searchFields: 'applicant.vacanciesSearch.searchFields',
    partTime: 'cluster.partTime',
};

interface VacancySavedSearchItemContentProps {
    data: VacancySavedSearchItem;
}

const VacancySavedSearchItemContent: TranslatedComponent<VacancySavedSearchItemContentProps> = ({ trls, data }) => {
    const {
        currencyCode,
        salary,
        area,
        withAddress,
        searchArgs,
        professionalRole,
        industry,
        notFromAgency,
        onlyWithSalary,
        searchField,
        partTime,
        searchPeriod,
    } = data;
    return (
        <>
            <VSpacingContainer default={8}>
                {salary && (
                    <Text typography="custom-2-semibold">
                        <FormatMoney currency={currencyCode}>{salary}</FormatMoney>
                    </Text>
                )}
                {area && (
                    <Text>
                        <div className={styles.vacancySavedSearchItemContentArea}>
                            {area.join(', ')}
                            <HSpacing default={16} />
                            {withAddress && (
                                <>
                                    <CircleFilledSize16 initial="tertiary" />
                                    <HSpacing default={16} />
                                    <Link href={`/search/vacancy/map?${searchArgs}&amp;L_is_autosearch=true`}>
                                        {trls[TrlKeys.withAddress]}
                                    </Link>
                                </>
                            )}
                        </div>
                    </Text>
                )}
                <VSpacingContainer default={4}>
                    {professionalRole.length > 0 && <Text>{professionalRole.join(', ')}</Text>}
                    {industry.length > 0 && <Text>{industry.join(', ')}</Text>}
                    <Text>
                        {trls[TrlKeys[notFromAgency ? 'exclude' : 'include']]} {trls[TrlKeys.fromAgencies]}
                    </Text>
                    <Text>
                        {trls[TrlKeys[onlyWithSalary ? 'exclude' : 'include']]} {trls[TrlKeys.woSalary]}
                    </Text>
                    {searchField.length > 0 && (
                        <Text>
                            {trls[TrlKeys.searchFields]} {searchField.join(', ')}
                        </Text>
                    )}
                    {partTime?.length > 0 && (
                        <Text>
                            {trls[TrlKeys.partTime]} {partTime.join(', ')}
                        </Text>
                    )}
                    <Text>
                        {trls[TrlKeys.searchPeriod]} {searchPeriod || trls[TrlKeys.searchPeriodDefault]}
                    </Text>
                </VSpacingContainer>
            </VSpacingContainer>
        </>
    );
};

export default translation(VacancySavedSearchItemContent);
